import 'flowbite';
import intlTelInput from 'intl-tel-input';
import Notify from 'simple-notify'


"use strict";

// Get the current URL
var currentUrl = new URL(window.location.href);

// Create a new URLSearchParams object based on the current URL's query parameters
var searchParams = currentUrl.searchParams;

// Remove the desired parameter
searchParams.delete('form_submit');

// Update the query string of the current URL
currentUrl.search = searchParams.toString();

// Replace the current URL with the updated URL
window.history.replaceState({}, '', currentUrl.href);


function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function getIp(callback) {
  fetch("https://ipapi.co/json")
  .then(function(res) { return res.json(); })
  .then(function(data) { callback(data.country_code); })
  .catch(function() { callback("us"); });
}

document.querySelectorAll(".number").forEach(function (phoneInputField) {
  intlTelInput(phoneInputField, {
    initialCountry: "auto",
    geoIpLookup: getIp,
    preferredCountries: ["AE", "IN"],
    separateDialCode:true,
    utilsScript:
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
  });
});

document.querySelectorAll(".number").forEach(function (numberInput) {
  numberInput.addEventListener("keydown", function (event) {
    // Allow backspace and delete keys
    if (event.key === "Backspace" || event.key === "Delete" || event.key === "+") {
        return;
    }

    // Allow only numbers
    if (isNaN(event.key)) {
        event.preventDefault();
    }
  });
});


var sendMailBtn = document.querySelectorAll(".sendMail");
sendMailBtn.forEach(function(sendMailBtn) {
  sendMailBtn.addEventListener("click", function() {
    var self= this;
    var btnName = this.innerText;
    var phoneInputField=self.closest("form").querySelector(".number");
    var name = self.closest("form").querySelector(".name").value;
    var email = self.closest("form").querySelector(".email").value;
    var phone = window.intlTelInputGlobals.getInstance(phoneInputField).getNumber();
    var project = self.closest("form").querySelector(".project_type").value;

    // phone number validation
    var numberError = window.intlTelInputGlobals.getInstance(phoneInputField).isValidNumber();


    var msg ="Request a Call Back";
    if(self.closest("form").querySelector(".message")){
      msg = self.closest("form").querySelector(".message").value;
    }

    if (name == "") {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Please enter your name',
        autoclose: true,
      });
      self.closest("form").querySelector(".name").focus();
    }else if (name.length > 30) {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Name is too long',
        autoclose: true,
      });
      self.closest("form").querySelector(".name").focus();
  } else if (email == "") {
        new Notify ({
          status: 'error',
          title: 'Error',
          text: 'Please enter your email address',
          autoclose: true,
        });
        self.closest("form").querySelector(".email").focus();
    } else if (!validateEmail(email)) {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Please enter valid email address',
        autoclose: true,
      });
      self.closest("form").querySelector(".email").focus();
    }else if (phone == "") {
        new Notify ({
          status: 'error',
          title: 'Error',
          text: 'Please enter your phone number',
          autoclose: true,
        });
        self.closest("form").querySelector(".number").focus();
    }else if (!numberError) {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Invalid phone number length for the selected country',
        autoclose: true,
      });
      self.closest("form").querySelector(".number").focus();
    }
    else if (msg.length > 500) {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Message is too long',
        autoclose: true,
      });
      self.closest("form").querySelector(".message").focus();
    } else if (msg == "") {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Please write your message',
        autoclose: true,
      });
      self.closest("form").querySelector(".message").focus();
    } else {
        self.setAttribute('disabled', '');
        self.innerText="Sending...";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "backend/mail.php");
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        // xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var response = xhr.responseText;
                    // var response = JSON.parse(xhr.responseText);
                    if(response == 1) {
                      document.querySelector("[data-modal-hide='contact-us-modal']").click();

                      new Notify ({
                        status: 'success',
                        title: 'Success',
                        text: 'Thank You, Our team will get back to you soon...',
                        autoclose: true,
                      });

                      // Get the current URL
                      var currentUrl = new URL(window.location.href);

                      // Create a new URLSearchParams object based on the current URL's query parameters
                      var searchParams = currentUrl.searchParams;

                      // Add a new parameter to the URL
                      searchParams.set('form_submit', 'form_success');

                      // Update the query string of the current URL
                      currentUrl.search = searchParams.toString();

                      // Replace the current URL with the updated URL
                      window.history.replaceState({}, '', currentUrl.href);

                      // Get the form element
                      var form = document.querySelectorAll("form");
                      if (form) {
                        for (var i = 0; i < form.length; i++) {
                          form[i].reset();
                        }
                      }

                    } else {
                        new Notify ({
                          status: 'error',
                          title: 'Error',
                          text: 'Something Went wrong! Please try again later...',
                          autoclose: true,
                        });
                    }
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                    'event': 'cf',
                    'response':{
                      'result':'thank_you',
                      'success_msg':'Thank you'
                    }
                    });
                } else {
                  new Notify ({
                    status: 'error',
                    title: 'Error',
                    text: 'Something Went wrong! Please try again later...',
                    autoclose: true,
                  });
                }
            }
            self.removeAttribute('disabled');
            self.innerText=btnName;
        };
        xhr.send("name=" + name + "&email=" + email + "&phone=" + encodeURIComponent(phone) + "&message=" + msg + "&project="+project);
    }
  });
});

    // Get the URL parameter
    function getUrlParameter(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    function scrollToSectionIfNotScrolled(sectionId) {
      var sectionElement = document.getElementById(sectionId);
      var elements = document.querySelectorAll("li>div.header-section-link");
      if (elements) {
        elements.forEach(function(e) {
          e.classList.remove("!text-[#d8d0c3db]");
        });
      }
      if (sectionElement) {
        var element = document.querySelector('li>div[data-name="'+sectionId+'"]');
        if (element) {
          element.classList.add("!text-[#d8d0c3db]");
        } 
        sectionElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        var navbarBtn=document.querySelector('[data-collapse-toggle="navbar-sticky"]');
        if(navbarBtn.getAttribute("aria-expanded")=="true"){
          navbarBtn.click();
        }
      }
    }
    
    // Usage: Call this function passing the section ID
    var sectionIdParameter = getUrlParameter('section');
    window.addEventListener('DOMContentLoaded', function() {
      setTimeout(() => {
        scrollToSectionIfNotScrolled(sectionIdParameter);
      }, 1000);
     
    });

    document.querySelectorAll(".header-section-link").forEach(function(e){
      e.addEventListener("click", function(){
        const name = this.getAttribute('data-name');
        scrollToSectionIfNotScrolled(name);
        var urlParams = new URLSearchParams(window.location.search);
        urlParams.set('section', name);
        var newUrl = window.location.pathname + '?' + urlParams.toString();
        window.history.pushState({}, '', newUrl);
      });
    });

if (typeof hbspt !== 'undefined') {
    hbspt.forms.create({
      region: "na1",
      portalId: "42853853",
      formId: "dc5d0625-9322-4e8e-a409-b811777d0ef3",
      target:".hScontactform"
  });
  hbspt.forms.create({
      region: "na1",
      portalId: "42853853",
      formId: "dc5d0625-9322-4e8e-a409-b811777d0ef3",
      target:".hScontactformModal"
  });
  }